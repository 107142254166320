import { AppColors, AppPalette, CustomAppPalette } from "./theme.model";

export const defaultAppPalette: AppPalette = {
  primary: {
    "0": "#000000",
    "5": "#091400",
    "10": "#112000",
    "20": "#203600",
    "25": "#284300",
    "30": "#314F00",
    "35": "#395C00",
    "40": "#426900",
    "50": "#5A831F",
    "60": "#729D37",
    "70": "#8CB94F",
    "80": "#A6D568",
    "90": "#C1F281",
    "95": "#D3FF97",
    "98": "#F0FFD6",
    "99": "#F9FFE8",
    "100": "#FFFFFF",
  },
  secondary: {
    "0": "#000000",
    "5": "#0B1303",
    "10": "#161E0B",
    "20": "#2A331E",
    "25": "#353E28",
    "30": "#414A33",
    "35": "#4C563E",
    "40": "#586249",
    "50": "#717B60",
    "60": "#8A9479",
    "70": "#A5AF92",
    "80": "#C0CBAC",
    "90": "#DCE7C7",
    "95": "#EAF5D5",
    "98": "#F3FEDD",
    "99": "#F9FFE8",
    "100": "#FFFFFF",
  },
  tertiary: {
    "0": "#000000",
    "5": "#001413",
    "10": "#00201E",
    "20": "#003734",
    "25": "#024340",
    "30": "#154F4B",
    "35": "#245B57",
    "40": "#316763",
    "50": "#4B807C",
    "60": "#659A95",
    "70": "#7FB5B0",
    "80": "#9AD1CB",
    "90": "#B6EDE7",
    "95": "#C4FBF6",
    "98": "#E3FFFB",
    "99": "#F2FFFD",
    "100": "#FFFFFF",
  },
  error: {
    "0": "#000000",
    "5": "#2D0001",
    "10": "#410002",
    "20": "#690005",
    "25": "#7E0007",
    "30": "#93000A",
    "35": "#A80710",
    "40": "#BA1A1A",
    "50": "#DE3730",
    "60": "#FF5449",
    "70": "#FF897D",
    "80": "#FFB4AB",
    "90": "#FFDAD6",
    "95": "#FFEDEA",
    "98": "#FFF8F7",
    "99": "#FFFBFF",
    "100": "#FFFFFF",
  },
  neutral: {
    "0": "#000000",
    "5": "#10110D",
    "10": "#1B1C18",
    "20": "#30312C",
    "25": "#3B3C37",
    "30": "#464742",
    "35": "#52534D",
    "40": "#5E5F59",
    "50": "#777771",
    "60": "#91918B",
    "70": "#ACACA5",
    "80": "#C7C7C0",
    "90": "#E3E3DB",
    "95": "#F2F1E9",
    "98": "#FBFAF2",
    "99": "#FEFCF5",
    "100": "#FFFFFF",
  },
  neutralVariant: {
    "0": "#000000",
    "5": "#0F1209",
    "10": "#191D14",
    "20": "#2E3228",
    "25": "#393D32",
    "30": "#44483D",
    "35": "#505448",
    "40": "#5C6054",
    "50": "#75796C",
    "60": "#8F9285",
    "70": "#A9AD9F",
    "80": "#C5C8B9",
    "90": "#E1E4D5",
    "95": "#EFF2E3",
    "98": "#F8FBEB",
    "99": "#FBFEEE",
    "100": "#FFFFFF",
  },
};

export const getAppColors = (customPalette: CustomAppPalette): AppColors => {
  if (!customPalette) {
    customPalette = defaultAppPalette;
  }
  const appColors: AppColors = {
    primary: customPalette.primary[50],
    onPrimary: customPalette.primary[100],
    primaryContainer: customPalette.primary[90],
    onPrimaryContainer: customPalette.primary[10],
    primaryFixed: customPalette.primary[90],
    onPrimaryFixed: customPalette.primary[10],
    primaryFixedDim: customPalette.primary[80],
    onPrimaryFixedVariant: customPalette.primary[30],
    secondary: customPalette.secondary[50], // was secondary 40
    onSecondary: customPalette.secondary[100],
    secondaryContainer: customPalette.secondary[90],
    onSecondaryContainer: customPalette.secondary[10],
    secondaryFixed: customPalette.secondary[90],
    onSecondaryFixed: customPalette.secondary[10],
    secondaryFixedDim: customPalette.secondary[80],
    onSecondaryFixedVariant: customPalette.secondary[30],
    tertiary: customPalette.tertiary[50], // was "#386662"
    onTertiary: customPalette.tertiary[100],
    tertiaryContainer: customPalette.tertiary[90], //was "#BCECE7"
    onTertiaryContainer: customPalette.tertiary[10],
    tertiaryFixed: customPalette.tertiary[90], //was "#BCECE7"
    onTertiaryFixed: customPalette.tertiary[10],
    tertiaryFixedDim: customPalette.tertiary[80], //was "#A0D0CB"
    onTertiaryFixedVariant: customPalette.tertiary[30], // was  "#1F4E4B"
    error: defaultAppPalette.error[40],
    onError: defaultAppPalette.error[100],
    errorContainer: defaultAppPalette.error[90],
    onErrorContainer: defaultAppPalette.error[10],
    outline: defaultAppPalette.neutral[50],
    background: defaultAppPalette.neutral["100"],
    onBackground: defaultAppPalette.neutral[10],
    surface: defaultAppPalette.neutral["98"],
    onSurface: defaultAppPalette.neutral[10],
    surfaceVariant: defaultAppPalette.neutralVariant[90],
    onSurfaceVariant: defaultAppPalette.neutralVariant[30],
    inverseSurface: defaultAppPalette.neutral[20],
    inverseOnSurface: defaultAppPalette.neutral[95],
    inversePrimary: customPalette.primary[80],
    shadow: defaultAppPalette.neutral[0],
    surfaceTint: customPalette.primary[40],
    outlineVariant: defaultAppPalette.neutralVariant[80],
    scrim: defaultAppPalette.neutralVariant[0],
    surfaceContainerHighest: defaultAppPalette.neutral[90],
    surfaceContainerHigh: defaultAppPalette.neutral["95"], //was "#E9E8E1"
    surfaceContainer: defaultAppPalette.neutral["98"], //"#EFEEE6"
    surfaceContainerLow: defaultAppPalette.neutral["99"], // was  "#F5F4EC"
    surfaceContainerLowest: defaultAppPalette.neutral[100],
    surfaceBright: defaultAppPalette.neutral["98"],
    surfaceDim: defaultAppPalette.neutral[80], // was "#DBDAD3"

    backdrop: defaultAppPalette.neutral[0],
    relation: { organization: "#0B79D0", person: "#C77700", tag: "#C51162" },
    planning: {
      // planningRowBackground: "#F4F5F4",
    },
    timesheet: {
      orange: "rgba(237, 108, 2, 1)",
      green: "rgba(123, 198, 126, 1)",
    },
    importance: {
      major: { fg: "#d82e18", bg: "#f2bdb4" },
      medium: { fg: "#db7c2e", bg: "#EAAF71" },
      normal: { fg: "#6b6f73", bg: "#edf1f4" },
      low: { fg: "#01a2fa", bg: "#c6e9ff" },
    },
  };

  return appColors;
};

// export const colors: CustomPalette = {
//   getAppColors(),
//   customPalette,

// };

// menu color: secondaryContainer
// hover color:
// selected color:
