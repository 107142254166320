export const hexToRgba = (hex: string, alpha: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const hexToRgb = (hex: string): [number, number, number] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

const rgbToHex = (r: number, g: number, b: number): string =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

const interpolateColor = (
  color1: [number, number, number],
  color2: [number, number, number],
  factor: number
): [number, number, number] =>
  color1.map((c, i) => Math.round(c + (color2[i] - c) * factor)) as [
    number,
    number,
    number
  ];

export const generateColorPaletteObject = (
  baseColor: string
): Record<string, string> => {
  const baseRgb = hexToRgb(baseColor);
  const blackRgb: [number, number, number] = [0, 0, 0];
  const whiteRgb: [number, number, number] = [255, 255, 255];

  const palette: Record<string, string> = {
    "0": "#000000",
    "5": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.1)),
    "10": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.2)),
    "20": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.4)),
    "25": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.5)),
    "30": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.6)),
    "35": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.7)),
    "40": rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.8)),
    "50": baseColor,
    "60": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.2)),
    "70": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.4)),
    "80": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.6)),
    "90": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.8)),
    "95": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.9)),
    "98": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.96)),
    "99": rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.98)),
    "100": "#FFFFFF",
  };

  return palette;
};

export const getColorShade = (baseColor: string, shade: string): string => {
  const baseRgb = hexToRgb(baseColor);
  const blackRgb: [number, number, number] = [0, 0, 0];
  const whiteRgb: [number, number, number] = [255, 255, 255];

  switch (shade) {
    case "0":
      return "#000000";
    case "5":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.1));
    case "10":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.2));
    case "20":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.4));
    case "25":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.5));
    case "30":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.6));
    case "35":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.7));
    case "40":
      return rgbToHex(...interpolateColor(blackRgb, baseRgb, 0.8));
    case "50":
      return baseColor;
    case "60":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.2));
    case "70":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.4));
    case "80":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.6));
    case "90":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.8));
    case "95":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.9));
    case "98":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.96));
    case "99":
      return rgbToHex(...interpolateColor(baseRgb, whiteRgb, 0.98));
    case "100":
      return "#FFFFFF";
    default:
      throw new Error(`Invalid shade: ${shade}`);
  }
};
