import { useTheme as useThemeReact } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { getAppColors, defaultAppPalette } from "./patron.colors";
import { dimensions } from "./dimensions";
import localFont from "next/font/local";
import { CustomAppPalette } from "./theme.model";

// Font files can be colocated inside of `app`
const Ubuntu = localFont({
  src: "./fonts/Ubuntu-Regular.ttf",
  display: "swap",
});
export const getTheme = (customPalette?: CustomAppPalette) => {
  // if there's nothing passed
  const customCombinedPalette = { ...defaultAppPalette, ...customPalette };

  const appColors = getAppColors(customCombinedPalette);
  // any because materialTheme typing expecting everything
  // eslint-disable-next-line
  const materialTheme = createTheme({
    spacing: 2,
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              background: "#f3f3f3",
              color: "#dadada",
            },
          },
        },
        defaultProps: {
          size: "small",
          variant: "filled",
          MenuProps: {
            PaperProps: {
              style: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: appColors.primaryFixedDim,
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: appColors.primaryFixed,
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: appColors.primaryFixed,
                },
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          componentsProps: {
            paper: {
              sx: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                "& .MuiAutocomplete-option.Mui-selected": {
                  backgroundColor: appColors.primaryFixedDim,
                },
                "& .MuiAutocomplete-option:hover": {
                  backgroundColor: appColors.primaryFixed,
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: appColors.primaryFixed,
                },
              },
            },
          },
        },
        styleOverrides: {
          root: {
            "& .Mui-disabled": {
              // background: "#f3f3f3",
              // color: "#dadada",
            },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            borderRadius: 12,
            padding: 0,
            paddingTop: 8,
            paddingBottom: 8,
            borderColor: appColors.outlineVariant,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            borderRadius: 24,
            height: 36,
            textTransform: "none",
          },
        },
      },
    },
    palette: {
      // type: "light",
      background: {
        default: customCombinedPalette.neutral["100"],
        paper: appColors.surfaceContainerLowest,
      },
      primary: {
        light: customCombinedPalette.primary[80],
        main: customCombinedPalette.primary[60],
        dark: customCombinedPalette.primary[30],
        contrastText: appColors.onPrimary,
      },
      secondary: {
        light: customCombinedPalette.secondary[60],
        main: appColors.secondary,
        dark: customCombinedPalette.secondary[30],
        contrastText: appColors.onSecondary,
      },
    },
    typography: {
      fontFamily: Ubuntu.style.fontFamily,
      fontSize: 14,
      // h1: {
      // h2: {
      //   fontSize: "1.9rem",
      // },
      // h3: {
      //   fontSize: "1.7rem",
      // },
      // h4: {
      //   fontSize: "1.5rem",
      // },
      // h5: {
      //   fontSize: "1.3rem",
      // },
      // h6: {
      //   fontSize: "1.1rem",
      // },
    },
  });
  return {
    ...materialTheme,
    // Override material default: show header above drawer (sidebar)
    zIndex: {
      ...materialTheme.zIndex,
      appBar: materialTheme.zIndex.drawer,
      drawer: materialTheme.zIndex.appBar,
    },
    appColors: appColors,
    appPalette: customCombinedPalette,
    dimensions,
  };
};

export type Theme = ReturnType<typeof getTheme>;

export const useTheme = () => useThemeReact() as Theme;
